import './App.css';
import { PrimeReactProvider } from 'primereact/api';

function App() {
  return (
    <PrimeReactProvider>
      <div className='App'>aritan.org</div>
    </PrimeReactProvider>
  );
}

export default App;
